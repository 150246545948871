import React, { useContext } from "react"
import { Link } from "gatsby"
import cx from "classnames"

import { LayoutContext } from "@layouts/BaseLayout"
import ToFormIcon from "@icons/ToForm.svg"

import * as s from "./LinkToForm.module.sass"

type LinkToFormProps = {
  withRhombus?: boolean
  className?: string
}

export const LinkToForm: React.FC<LinkToFormProps> = ({
  withRhombus = false,
  className,
}) => {
  const { langKey } = useContext(LayoutContext)

  return (
    <Link
      to={langKey === "ua" ? "/form" : "/ru/form"}
      className={cx(s.link, { [s.withRhombus]: withRhombus }, className)}
      aria-label={
        langKey === "ua" ? "Форма для заказа" : "Форма для замовлення"
      }
    >
      <ToFormIcon />
    </Link>
  )
}
